<script lang="ts">
import { ComponentPublicInstance, defineComponent, ref } from 'vue'

import { getAssetUrl } from './utils/getAssetUrl'

export type PreloaderPublicInstance = ComponentPublicInstance & {
  visible: boolean
}

export default defineComponent({
  name: 'ThePreloader',
  setup () {
    return {
      getAssetUrl,
      visible: ref(true)
    }
  }
})
</script>

<template>
  <div id="app-loading-container">
    <transition>
      <div v-if="visible" id="app-loading">
        <img class="preloader-logo" :src="getAssetUrl('images/logo/logo.png')">
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
#app-loading {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    0deg,
    hsl(2deg 87% 43%) 0%,
    hsl(354deg 89% 42%) 4%,
    hsl(347deg 90% 42%) 9%,
    hsl(339deg 92% 42%) 13%,
    hsl(332deg 94% 41%) 17%,
    hsl(324deg 95% 41%) 22%,
    hsl(317deg 97% 41%) 26%,
    hsl(309deg 99% 40%) 30%,
    hsl(301deg 100% 40%) 35%,
    hsl(292deg 100% 40%) 39%,
    hsl(283deg 100% 40%) 43%,
    hsl(274deg 100% 40%) 48%,
    hsl(265deg 100% 40%) 52%,
    hsl(256deg 100% 40%) 57%,
    hsl(247deg 100% 40%) 61%,
    hsl(238deg 100% 40%) 65%,
    hsl(229deg 100% 40%) 70%,
    hsl(221deg 100% 40%) 74%,
    hsl(212deg 100% 40%) 78%,
    hsl(204deg 100% 40%) 83%,
    hsl(195deg 100% 40%) 87%,
    hsl(186deg 100% 40%) 91%,
    hsl(178deg 100% 40%) 96%,
    hsl(169deg 100% 40%) 100%
  )
}

img {
  width: rem(280px);
}

.preloader-logo {
  animation: fade-in 2s infinite ease-in-out alternate;
}

@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}
</style>
